import { Dispatch, SetStateAction, useState } from 'react';

export default function AlertViewModel(): IAlertViewModel {

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  
  const show = ({msg, title = ''} : IAlertViewMessage) => {
    setTitle(title);
    setMessage(msg);
  };

  return {
  	title, setTitle,
  	message, setMessage,
    show
  };

}

export interface IAlertViewModel{
  title: string;
  setTitle: (value: string) => void;
  message: string;
  setMessage: (value: string) => void;
  show: (args: IAlertViewMessage) => void;
}

interface IAlertViewMessage{
  msg: string;
  title?: string;
}
