import React from "react"
import { Ban, CheckLg } from 'react-bootstrap-icons';
import InputField from "../common/components/InputField.tsx";
import IconButton from "./IconButton.tsx";
import { ITodaysLogsViewModel } from "../js/viewmodels/TodaysLogsViewModel.ts";
import { IAlertViewModel } from "../common/js/viewmodels/AlertViewModel.ts";

export default function DeleteLogModal(args: IDeleteLogModal){

    const { model, alertModel, error } = args;

    if (alertModel.message === ""){
        return (<></>);
    }

    const yes = () => {
        model.removeLog(error);
        alertModel.setMessage('');
    };

    const cancel = () => {
        alertModel.setMessage('');
    };

    return (
        <div id="viewLogModal" className="modal show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ alertModel.title }</h5>
                        <button type="button" className="btn-close" onClick={cancel}></button>
                    </div>
                    <div className="modal-body">

                        <p>{ alertModel.message }</p>

                        <InputField
                            id="customer"
                            name="Customer"
                            value={model.activeLog.customer.name}
                            readonly={true}
                            />

                        <InputField
                            id="caller"
                            name="Caller"
                            value={model.activeLog.caller}
                            readonly={true}
                            />

                        <InputField
                            id="problem"
                            name="Problem"
                            value={model.activeLog.problem}
                            readonly={true}
                            />

                        <InputField
                            id="solution"
                            name="Solution"
                            value={model.activeLog.solution}
                            readonly={true}
                            />

                        <InputField
                            id="timeTaken"
                            name="Time Taken"
                            value={model.activeLog.displayTime()}
                            readonly={true}
                            />

                    </div>

                    <div className="modal-footer">
                        <IconButton
                            btnStyle="success"
                            text="Yes"
                            onClick={yes}
                            icon={<CheckLg/>}
                            show={true}
                            />
                        <IconButton
                            btnStyle="danger"
                            text="No"
                            onClick={cancel}
                            icon={<Ban/>}
                            show={true}
                            />
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IDeleteLogModal{
    model: ITodaysLogsViewModel;
    alertModel: IAlertViewModel;
    error: (msg: string) => void;
}
