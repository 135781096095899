import React from "react"
// import DateTimePicker from "react-datetime"; // import react-datetime for datepicker
import { IArchivedLogModalViewModel } from "../js/viewmodels/ArchivedLogModalViewModel";
import { CheckLg } from 'react-bootstrap-icons';
import { IArchivedLogEditModalViewModel } from "../js/viewmodels/ArchivedEditLogModelViewModel.ts";
import InputField from "../common/components/InputField.tsx";
import Autocomplete from "./Autocomplete.tsx";
import UserAutocomplete from "./UserAutocomplete.tsx";
import IconButton from "./IconButton.tsx";
import { IAlertViewModel } from "../common/js/viewmodels/AlertViewModel.ts";
import { IHistoryScreenViewModel } from "../js/viewmodels/HistoryScreenViewModel.ts";

export default function ArchivedLogEditModel (args: IArchiveLogEditModal){

    const { historymodel, model, editmodel, alertModel } = args;

    if (editmodel.showModel === false ){
        return (<></>);
    }
    return (
        <div id="viewLogModal" className="modal show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Transaction</h5>
                        <button type="button" className="btn-close" onClick={editmodel.cancel}></button>
                    </div>
                    <div className="modal-body">

                        <Autocomplete
                            name="Customer"
                            id="notesCustomer"
                            datalist={editmodel.customers}
                            defaultValue={editmodel.customer ? editmodel.customer : undefined}
                            setValue={editmodel.setCustomer}
                            labelKey="name"
                            clearOnComplete={false}
                            />
                        <UserAutocomplete
                            name="User"
                            id="User"
                            datalist={editmodel.users} // Users list set from logs
                            defaultValue={editmodel.user ? editmodel.user : undefined}
                            setValue={editmodel.setUser}
                            labelKey="u_name"
                            clearOnComplete={false}
                        />
                        <InputField
                            id="date"
                            name="Start Date"
                            type="datetime-local"
                            value={editmodel.startDateTime}
                            setValue={editmodel.setStartDateTime}
                            />
                        <InputField
                            id="date"
                            name="End Date"
                            type="datetime-local"
                            value={editmodel.endDateTime}
                            setValue={editmodel.setEndDateTime}
                            />  

                        <InputField
                            id="timeTaken"
                            name="Time Taken"
                            value={editmodel.timeTaken}
                            setValue={editmodel.setTimeTaken}
                            />
                        <InputField
                            id="caller"
                            name="Caller"
                            value={editmodel.caller}
                            setValue={editmodel.setCaller}
                            />
                        <InputField
                            id="problem"
                            name="Problem"
                            value={editmodel.problem}
                            setValue={editmodel.setProblem}
                            />
                        <InputField
                            id="solution"
                            name="Solution"
                            value={editmodel.solution}
                            setValue={editmodel.setSolution}
                            />

                    </div>

                    <Footer historymodel={historymodel} model={model} editmodel={editmodel} alertModel={alertModel}/>
                </div>
            </div>
        </div>
    );
}

function Footer({ historymodel, model, editmodel, alertModel }: IArchiveLogEditModal){


    const submitLog = () => { 
        editmodel.submitLog(historymodel, model,
        (error) => {
            alertModel.show({
                title: 'Error',
                msg:error
            });
        }
    )
};

    return (
<div className="modal-footer">
    <IconButton
        btnStyle="success"
        text="Save"
        onClick={submitLog}
        icon={<CheckLg />}
        show={true}
        inProgress={false}
        inProgressText="Saving..."
    />
    <button className="btn btn-secondary" onClick={editmodel.cancel}>
        Close
    </button>
</div>
    );
}

interface IArchiveLogEditModal{
    historymodel: IHistoryScreenViewModel;
    editmodel: IArchivedLogEditModalViewModel;
    model: IArchivedLogModalViewModel;
    alertModel: IAlertViewModel;
}
