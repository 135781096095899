import React from "react"
import { Ban, CheckLg } from 'react-bootstrap-icons';
import { IActiveLogModalViewModel } from "../js/viewmodels/ActiveLogModalViewModel";
import InputField from "../common/components/InputField.tsx";
import Autocomplete from "./Autocomplete.tsx";
import IconButton from "./IconButton.tsx";
import { IAlertViewModel } from "../common/js/viewmodels/AlertViewModel";
import { ITodaysLogsViewModel } from "../js/viewmodels/TodaysLogsViewModel.ts";

export default function ActiveLogModal(args: IActiveLogModal){

    const { model, alertModel, todaysLogsModel } = args;

    if (model.log === null){
        return (<></>);
    }

    return (
        <div id="viewLogModal" className="modal show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Log</h5>
                        <button type="button" className="btn-close" onClick={model.cancel}></button>
                    </div>
                    <div className="modal-body">

                        <Autocomplete
                            name="Customer"
                            id="notesCustomer"
                            datalist={model.customers}
                            defaultValue={model.log.customer}
                            setValue={model.setCustomer}
                            labelKey="name"
                            clearOnComplete={false}
                            />
                        <InputField
                            id="date"
                            name="Date"
                            value={model.date}
                            setValue={model.setDate}
                            />
                        <InputField
                            id="timeTaken"
                            name="Time Taken"
                            value={model.timeTaken}
                            setValue={model.setTimeTaken}
                            />
                        <InputField
                            id="caller"
                            name="Caller"
                            value={model.caller}
                            setValue={model.setCaller}
                            />
                        <InputField
                            id="problem"
                            name="Problem"
                            value={model.problem}
                            setValue={model.setProblem}
                            />
                        <InputField
                            id="solution"
                            name="Solution"
                            value={model.solution}
                            setValue={model.setSolution}
                            />

                    </div>

                    <Footer model={model} alertModel={alertModel} todaysLogsModel={todaysLogsModel}/>
                </div>
            </div>
        </div>
    );
}

function Footer({ model, alertModel, todaysLogsModel }: IActiveLogModal){

    const submit = () => {
        console.log("Submit");
        model.submit(todaysLogsModel, (error) => {
            alertModel.show({
                title: 'Error',
                msg:error
            });
        });
    };
    return (
        <div className="modal-footer">
            <IconButton
                btnStyle="success"
                text="Save"
                onClick={submit}
                icon={<CheckLg/>}
                show={true}
                inProgress={todaysLogsModel.requestInProgress}
                inProgressText="Saving..."
                />
            <IconButton
                btnStyle="danger"
                text="Cancel"
                onClick={model.cancel}
                icon={<Ban/>}
                show={!todaysLogsModel.requestInProgress}
                />
        </div>
    );
}

interface IActiveLogModal{
    model: IActiveLogModalViewModel;
    alertModel: IAlertViewModel;
    todaysLogsModel: ITodaysLogsViewModel;
}
