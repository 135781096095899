import { Dispatch, SetStateAction, useState, useEffect } from "react";
import NewLog from "../classes/NewLog.ts";
import { DateTime, Duration } from "luxon";
import Customer from "../classes/Customer";
import { ITodaysLogsViewModel } from "./TodaysLogsViewModel.ts";
import { use } from "../../../../../backend/react-node-app/server/inc/azurecallback.js";

export default function ActiveLogModalViewModel(): IActiveLogModalViewModel {
    const [defaultTimeFormat, setDefaultTimeFormat] = useState<string>("h:mm");
    const [log, setLog] = useState<NewLog | null>(null);
    const [solution, setSolution] = useState<string>("");
    const [problem, setProblem] = useState<string>("");
    const [caller, setCaller] = useState<string>("");
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [customers, setCustomers] = useState<Array<Customer>>([]);
    const [date, setDate] = useState<string>("");
    const [timeTaken, setTimeTaken] = useState<string>("");


    const show = (newLog: NewLog) => {
        setSolution(newLog.solution);
        setProblem(newLog.problem);
        setCaller(newLog.caller);
        setCustomer(newLog.customer);
        setDate(newLog.start.toFormat('yyyy-MM-dd'));
        setTimeTaken(newLog.total.toFormat(defaultTimeFormat));
        setLog(newLog);
    };



    useEffect(() => {
        const savedSettings = JSON.parse(localStorage.getItem("userSettings") || "{}");
    
        const timeformat = savedSettings.defaultLogEditFormat || 'hh:mm';
    
        setDefaultTimeFormat(timeformat);
    }, [localStorage.getItem("userSettings")]);



    const cancel = () => setLog(null);

    const submit = (model: ITodaysLogsViewModel, error: (msg: string) => void) => {
        if (log === null) {
            error("Log is required");
            cancel();
            return;
        }
        if (customer === null) {
            error("Customer is required");
            cancel();
            return;
        }
        if (!timeTaken) {
            error("Time taken is required");
            cancel();
            return;
        }
        if (!date) {
            error("Date is required");
            cancel();
            return;
        }
    
        

        const timeFormats: string[] = ["hh 'hours and' mm 'minutes'", "hh:mm:ss", "h:mm:ss", "hh:mm", "h:mm"];
        let validParsedTime: Duration | null = null;
        
        if (/^\d+$/.test(timeTaken)) {
            // If `timeTaken` is just digits (e.g., "30"), treat it as minutes
            validParsedTime = Duration.fromObject({ minutes: parseInt(timeTaken, 10) });
        } else {
            // Try to parse `timeTaken` with each format until one is valid
            for (const format of timeFormats) {
                const parsedTime = DateTime.fromFormat(timeTaken, format);
                
                if (parsedTime.isValid) {
                    console.log('Valid format:', format);
                    validParsedTime = Duration.fromObject({
                        hours: parsedTime.hour ?? 0,
                        minutes: parsedTime.minute ?? 0,
                        seconds: parsedTime.second ?? 0,
                    });
                    break;
                }
            }
        }
        
        if (!validParsedTime) {
            error("Invalid date format");
            cancel();
            return;
        }
        
        const startDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
        if (!startDate.isValid) {
            error("Invalid date format");
            cancel();
            return;
        }
        
        // Calculate total seconds from the Duration object
        const totalSeconds = validParsedTime.as('seconds');


        const now = DateTime.local();
    
        // Calculate the starting time


        //so to make these "LOOK REAL" I'm aussming 
        // noone is going to be doing this outside work hours so
        // lets use there current time 
        
        
        let startTime;
        if (startDate.hasSame(now, 'day', {})) {
            // If the date is today, use the current time minus the time taken
            startTime = now.minus({ seconds: totalSeconds });
        } else {
            // If the date is in the past, adjust the start date to account for the total time
            startTime = startDate.plus({ seconds: (now.hour * 3600 + now.minute * 60 + now.second) }).minus({ seconds: totalSeconds });
        }
    
        // Ensure startTime is within the bounds of the startDate
        if (startTime < startDate) {
            startTime = startDate.set({ hour: 0, minute: 0, second: 0 }).plus({ seconds: (24 * 3600 - totalSeconds) });
        }
    
        const newLog = new NewLog(customer, {
            l_id: log.id,
            l_start: startTime.toFormat('yyyy-MM-dd HH:mm:ss'),
            l_total: totalSeconds,
            l_cust: customer.code,
            l_caller: caller,
            l_problem: problem,
            l_solution: solution
        });

        model.save(newLog, error, () => {
            model.activeLog.setLog(newLog)
            cancel();
        });
    };

    return {
        log,
        show,
        cancel,
        submit,
        solution, setSolution,
        problem, setProblem,
        customer, setCustomer,
        caller, setCaller,
        date, setDate,
        timeTaken, setTimeTaken,
        customers, setCustomers
    };
}

export interface IActiveLogModalViewModel {
    log: NewLog | null;
    show: (log: NewLog) => void;
    submit: (model: ITodaysLogsViewModel, error: (msg: string) => void) => void;
    cancel: () => void;
    solution: string;
    setSolution: (value: string) => void;
    problem: string;
    setProblem: (value: string) => void;
    customer: Customer | null;
    customers: Array<Customer>;
    setCustomer: Dispatch<SetStateAction<Customer | null>>;
    caller: string;
    setCaller: (value: string) => void;
    date: string;
    setDate: (value: string) => void;
    timeTaken: string;
    setTimeTaken: (value: string) => void;
    // setDefaultTimeFormat: (value: string) => void;
    setCustomers: Dispatch<SetStateAction<Array<Customer>>>;
}
