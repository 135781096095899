import { useState, useEffect } from "react";
import User from "../classes/User.ts";

export default function UserSettingsViewModel(): IUserSettingsViewModel {
    const [user, setUser] = useState<User | null>(null);
    const [userSettings, setUserSettings] = useState<IUserSettings>(() => {
        const savedSettings = localStorage.getItem("userSettings");
        return savedSettings ? JSON.parse(savedSettings) : {
            defaultLogEditFormat: 'hh:mm', // Default value if no saved settings
            titleChange: false,
            darkMode: false,
            showEmojis: false,
        };
    });

    const [callback, setCallback] = useState<((newSettings: IUserSettings) => void) | null>(null);

    const show = (user: User) => {
        setUser(user);
    };

    const updateUserSettings = (settings: Partial<IUserSettings>) => {
        setUserSettings((prevSettings) => ({
            ...prevSettings,
            ...settings
        }));
    };

    const hide = () => {
        setUser(null);
    };

    const submit = () => {
        // console.log("Submit");
        localStorage.setItem("userSettings", JSON.stringify(userSettings));
        // console.log("Settings saved to localStorage:", userSettings);

        if (callback) {
            //callback app level
            callback(userSettings);
        }
        hide();
    };

    // Function to set the callback
    const registerCallback = (cb: (newSettings: IUserSettings) => void) => {
        setCallback(() => cb);
    };

    return {
        user,
        userSettings,
        show,
        hide,
        submit,
        setUserSettings: updateUserSettings,
        registerCallback  
    };
}

export interface IUserSettingsViewModel {
    user: User | null;
    userSettings: IUserSettings;
    show: (user: User) => void;
    hide: () => void;
    submit: () => void;
    setUserSettings: (settings: Partial<IUserSettings>) => void;
    registerCallback: (cb: (newSettings: IUserSettings) => void) => void;  
}

export interface IUserSettings {
    defaultLogEditFormat: string;
    titleChange: boolean;
    showEmojis: boolean;
    darkMode: boolean;
    // showManagementTab: boolean;
}
