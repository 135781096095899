	export default class Customer{

	code: string;
	name: string;
	group: string;
	publicNote: string;
	privateNote: string;
	publicGroupNote: string;
	privateGroupNote: string;
	settings: Array<ICustomerSetting>;
	groupSettings: Array<ICustomerSetting>;
	customerHoursMins: number;
	customerHoursText: string;

	constructor(val: ICustomer){
		this.code = val.code;
		this.name = val.name;
		this.group = val.group;
		this.publicNote = val.publicNote;
		this.privateNote = val.privateNote;
		this.publicGroupNote = val.publicGroupNote;
		this.privateGroupNote = val.privateGroupNote;
		this.settings = val.settings;
		this.groupSettings = val.groupSettings;
		this.customerHoursMins = val.customerHoursMins;
		this.customerHoursText = val.customerHoursText;
	}

	isPrb(): boolean {
		const cPrb = this.settings.find(s => s.key === 'isPrb');
		if (cPrb) return cPrb.value === 'true';
	  
		const gPrb = this.groupSettings.find(s => s.key === 'isPrb');
		if (gPrb) return gPrb.value === 'true';
	  
		return false;
	  }

	static createEmpty(){
		return new Customer({
			code: '',
			name: '',
			group: '',
			publicNote: '',
			privateNote: '',
			publicGroupNote: '',
			privateGroupNote: '',
			settings: [],
			groupSettings: [],
			customerHoursMins: 0,
			customerHoursText: '',
		});
	}
}

export interface ICustomer{
	code: string;
	name: string;
	group: string;
	publicNote: string;
	privateNote: string;
	publicGroupNote: string;
	privateGroupNote: string;
	settings: Array<ICustomerSetting>;
	groupSettings: Array<ICustomerSetting>;
	customerHoursMins: number;
	customerHoursText: string;
}

interface ICustomerSetting {
	group: string;
	key: string;
	value: string;
}