import { useState, useEffect } from "react";
import { defaultHandleSave } from "./DefaultHandleSave.jsx";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";

const useForm = (fields, customerDetails, customerOptions) => {
  const initialFormData = fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.id]: field.type === "typeahead" ? [] : (field.type === "checkbox" ? false : ""),
    }),
    {}
  );

  const [formData, setFormData] = useState(initialFormData);
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const newFormData = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.id]:
          field.type === "typeahead"
            ? [
                customerOptions.find(
                  (option) => option === customerDetails[field.key]
                ) || null,
              ].filter(Boolean)
            : (field.type === "checkbox"
              ? customerDetails[field.key] || false
              : customerDetails[field.key] || ""),
      }),
      {}
    );
    setFormData(newFormData);
  }, [customerDetails, customerOptions, fields]);

  const handleTypeaheadChange = (selected, fieldId) => {
    setFormData((prev) => ({ ...prev, [fieldId]: selected }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData((prev) => ({ ...prev, [id]: checked ? 'Y' : 'N' }));
  };

  const handleDateChange = (fieldId, value) => {
    if (!value) {
      setFormData((prev) => ({
        ...prev,
        [fieldId]: '', 
      }));
      return;
    }
  
    const formattedDate = value.format('YYYY-MM-DD HH:mm:ss'); 
  
    setFormData((prev) => ({
      ...prev,
      [fieldId]: formattedDate, 
    }));
  };
  
  

  const handleSave = () =>
    defaultHandleSave(
      customerDetails,
      fields,
      formData,
      HttpClientUtil,
      setResponseMessage,
      setIsSuccess,
      setShowResponseDiv
    );

  return {
    formData,
    handleTypeaheadChange,
    handleInputChange,
    handleCheckboxChange,
    handleDateChange, // Expose the date change handler
    handleSave,
    showErrorDiv,
    setShowResponseDiv,
    responseMessage,
    isSuccess,
  };
};

export default useForm;
