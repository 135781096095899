import React, { useState, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.css";
import "./common/css/style.css";
import "./css/style.css";
import "./css/App.css";
import TabVisibilityHandler from "./components/tabVisability/tabVisability.tsx";
import NavBar from "./components/navbar/NavBar.tsx";
import ProgressModal from "./components/ProgressModal";
import CustomerMaintenanceSection from "./components/Maintenance/CustomerMaintenanceSection.tsx";
import CustomerReports from "./components/CustomerReports";
import AdminScreenViewModel from "./js/viewmodels/AdminScreenViewModel.ts";
import Admin from "./components/Admin.tsx";
import UsersScreen from "./components/users/Users.tsx";
import UserTableViewModel from "./js/viewmodels/UsersScreenViewModel.ts";
import User from "./js/classes/User.ts";
import CustomerGroup from "./js/classes/CustomerGroup.ts";
import TodaysLogsScreen from "./components/TodaysLogsScreen.tsx";
import HistoryScreen from "./components/HistoryScreen.tsx";
import CustomerScreen from "./components/CustomerScreen.tsx";
import TodaysLogsViewModel from "./js/viewmodels/TodaysLogsViewModel.ts";
import CustomerScreenViewModel from "./js/viewmodels/CustomerScreenViewModel.ts";
import HistoryScreenViewModel from "./js/viewmodels/HistoryScreenViewModel.ts";
import CustomerMaintenanceViewModel from "./js/viewmodels/CustomerMaintenanceSection.ts";
import Customer from "./js/classes/Customer";
import LoadingScreen from "./components/LoadingScreen.tsx";

import UserSettingsViewModel, {
} from "./js/viewmodels/UserSettingsViewModel.ts";

function App() {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [enabletitleChange, setEnableTitleChange] = useState<boolean>(false);


  const [showManagementTab, setShowManagementTab] = useState<boolean>(false);



  const adminScreenViewModel = AdminScreenViewModel();
  const todaysLogsModel = TodaysLogsViewModel();
  const customerScreenViewModel = CustomerScreenViewModel();
  const userTableViewModel = UserTableViewModel(); // Make sure it's a function or class constructor
  const historyScreenViewModel = HistoryScreenViewModel();
  const settingsViewModel = UserSettingsViewModel();
  const customerMaintenanceViewModel = CustomerMaintenanceViewModel();
  const [selectedTab, setTab] = useState<string>("");

  useEffect(() => {
    //use effect no param watching to call local storage once

    settingsViewModel.registerCallback((settings) => {
      setIsDarkMode(settings.darkMode);
      setShowEmojis(settings.showEmojis);
      setEnableTitleChange(settings.titleChange);
    });


    setIsDarkMode(settingsViewModel.userSettings.darkMode);
    setShowEmojis(settingsViewModel.userSettings.showEmojis);
    setEnableTitleChange(settingsViewModel.userSettings.titleChange);     



  }, [localStorage.getItem("userSettings")]);

  const setTabToday = () => setTab("today");

  const setUser = (user: User) => {
    todaysLogsModel.setUser(user);
    historyScreenViewModel.setUser(user);

    if (user.u_groups.includes("admin") || user.u_name === "joshua") {
      setShowManagementTab(true);
    }
  };

  const setCustomers = (customers: Array<Customer>) => {
    adminScreenViewModel.setCustomers(customers);
    todaysLogsModel.setCustomers(customers);
    historyScreenViewModel.setCustomers(customers);
    customerScreenViewModel.setCustomers(customers);
  };

  const setUsers = (users: Array<User>) => {
    userTableViewModel.setUsersGlobal(users, (updatedUsers) => {
      // console.log(`Updated users: ${JSON.stringify(updatedUsers)}`);
      historyScreenViewModel.setUsers(updatedUsers);
    });
  };

  const setGroups = (groups: Array<CustomerGroup>) => {

    customerMaintenanceViewModel.setGroupsGlobal(groups, (updatedGroups) => {

      userTableViewModel.setGroups(updatedGroups);
    });


  };

  const renderSection = () => {
    switch (selectedTab) {
      case "today":
        return (
          <TodaysLogsScreen
            model={todaysLogsModel}
            settingsModel={settingsViewModel}
          />
        );
      case "history":
        return <HistoryScreen model={historyScreenViewModel} />;
      case "customers":
        return <CustomerScreen model={customerScreenViewModel} />;
      case "maintenance":
        return (
          <CustomerMaintenanceSection model={customerMaintenanceViewModel} />
        );
      case "reports":
        return <CustomerReports />;
      case "admin":
        return <Admin model={adminScreenViewModel} />;
      case "users":
        return <UsersScreen model={userTableViewModel} />;
      default:
        return (
          <LoadingScreen
            setUser={setUser}
            setGroups={setGroups}
            setLogs={todaysLogsModel.setLogs}
            setCustomers={setCustomers}
            setUsers={setUsers}
            finished={setTabToday}
          />
        );
    }
  };

  const tabs = [
    { tab: "today", text: "Today" },
    { tab: "history", text: "History" },
    { tab: "customers", text: "Customers" },
    // { tab: 'reports', text: 'Statistics' },

  ];
  if (showManagementTab) {
    tabs.push(
      { tab: "reports", text: "Customer Reports" },
      // { tab: 'reports', text: 'Statistics' },
      {
        tab: "maintain",
        text: "Maintain",
        dropdown: [
          { tab: "maintenance", text: "Customer Maintenance" },
          { tab: "users", text: "Users" },
          { tab: "admin", text: "Admin" },
        ],
      },
  );
  }

  return (
    <div
      className={`App ${
        isDarkMode ? "bg-dark text-light" : "bg-light text-dark"
      } min-vh-100 d-flex flex-column`}
    >
      <TabVisibilityHandler
        activeTitle={`${showEmojis ? "🥹 " : ""}Hubbling!`}
        inactiveTitle={`${showEmojis ? "🫠 " : ""}Hubble Back?!`}
        enabletitleChange={enabletitleChange}
      />
      <ProgressModal />
      <NavBar
        title="Hubble"
        tabs={tabs}
        selectedTab={selectedTab}
        setTab={setTab}
      />
      <br />
      <main
        className={`container flex-grow-1 mt-lg-4 ${
          isDarkMode ? "bg-dark text-light" : "bg-light text-dark"
        }`}
        data-bs-theme={isDarkMode ? "dark" : "light"}
      >
        {renderSection()}
      </main>
    </div>
  );
}

export default App;
