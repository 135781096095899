import {  useState } from 'react';
import NewLog from '../classes/NewLog.ts';
import User from '../classes/User.ts';
import CustomerGroup from '../classes/CustomerGroup.ts';
import Settings from '../classes/Settings.ts';
import Customer from '../classes/Customer.ts';
import AjaxFn, { ErrorResponse } from '../util/AjaxFn.ts';

export default function LoadingScreenViewModel(){

	const [message, setMessage] = useState("Loading...");

	const loadSession = ({
		setUser,
		setGroups,
		setLogs,
		setCustomers,
		setUsers,
		finished
	}: ILoadingScreenViewModel) => {
		AjaxFn.getSession({
			callback: (user: User, groups: Array<CustomerGroup>, users: Array<User>, customers: Array<Customer>, logs: Array<NewLog>) => {
				setUser(user);
				setGroups(groups);
				setCustomers(customers);
				setUsers(users);
				setLogs(logs);
				finished();
			},
			errCallback: (response: ErrorResponse) => {
				if (response.loginUrl){
					window.location.href = response.loginUrl;
				}else{
					setMessage(response.data);
				}
			},
			setRequestInProgress: () => {}
		});
	};

	return {
		message, setMessage,
		loadSession
	};

}

export interface ILoadingScreenViewModel{
	setUser: (user: User) => void;
	setGroups: (groups: Array<CustomerGroup>) => void;
	setLogs: (logs: Array<NewLog>) => void;
	setCustomers: (customers: Array<Customer>) => void;
	setUsers: (users: Array<User>) => void;
	finished: () => void;
}
