export const basicInfoFields = [
    { key: "C_NAME", label: "Customer Name", id: "customerName", type: "text" },
    { key: "C_ADDR1", label: "Invoice Name", id: "invoiceName", type: "text" },
    {
      key: "C_ADDR2",
      label: "Invoice Address",
      id: "invoiceAddress",
      type: "text",
    },
    {
      key: "C_ADDR3",
      label: "Invoice Address Suburb",
      id: "invoiceAddressSuburb",
      type: "text",
    },
    { key: "C_STATE", label: "State", id: "state", type: "text" },
    { key: "C_PCODE", label: "Postcode", id: "postcode", type: "text" },
    {
      key: "C_SSH",
      label: "SSH Command Line",
      id: "sshCommandLine",
      type: "text",
    },
    {
      key: "C_UPGRADED",
      label: "Last Upgraded",
      id: "lastUpgraded",
      type: "datetime-local",
    },
    { key: "C_DIAL", label: "Speed Dial", id: "speedDial", type: "text" },
  ];
  
  export const siteDetailsFields = [
    { key: "C_PADDR1", label: "Site Name", id: "siteName", type: "text" },
    { key: "C_PADDR2", label: "Site Address", id: "siteAddress", type: "text" },
    {
      key: "C_PADDR3",
      label: "Site Address Suburb",
      id: "siteAddressSuburb",
      type: "text",
    },
    { key: "C_PSTATE", label: "State", id: "state", type: "text" },
    { key: "C_PPCODE", label: "Postcode", id: "postcode", type: "text" },
    {
      key: "C_PATT",
      label: "When Mailing Attention",
      id: "mailingAttention",
      type: "text",
    },
  ];

  export const billingDetailsFields = [
    { key: "C_BILLTO", label: "Bill To", id: "billTo", type: "typeahead" },
    {
      key: "C_PERIODLEN",
      label: "Billing Period Length in Months",
      id: "billPeriod",
      type: "text",
    },
    {
      key: "C_STARTED",
      label: "Contract Start Date",
      id: "contractStart",
      type: "datetime-local",
    },
    {
      key: "C_HOURSPER",
      label: "Hours Per Period",
      id: "hoursPerPeriod",
      type: "text",
    },
    {
      key: "C_MINUTESUSED",
      label: "Minutes USED End of last month",
      id: "minutesUsed",
      type: "text",
    },
    {
      key: "C_ONHOLD",
      label: "Put Customer On Hold for Support",
      id: "holdSupport",
      type: "checkbox",
    },
    {
      key: "C_SUMMARY",
      label: "Generate Group Summary",
      id: "groupSummary",
      type: "checkbox",
    },
    {
      key: "C_PRINTINVOICE",
      label: "Generate Summary Reports",
      id: "summaryReports",
      type: "checkbox",
    },
    {
      
      key: "C_INVOICED",
      label: "Invoiced Format",
      id: "invoicedFormat",
      type: "select",
      options: [
        { value: "", label: "None" },
        { value: "M", label: "Monthly" },
        { value: "Q", label: "Quarterly" },
        { value: "H", label: "Half Yearly" },
        { value: "Y", label: "Yearly" },
      ]
    }
  ];