import React from "react";
import { IUserSettingsViewModel } from "../../../js/viewmodels/UserSettingsViewModel";

export default function SettingsModal(args: ISettingsModal) {
  const { viewModel } = args;

  if (!viewModel.user) {
    return <></>;
  }

  const handleCheckboxChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      viewModel.setUserSettings({ [name]: e.target.checked });
    };

  const handleSelectChange = (name: string, value: string) => {
    viewModel.setUserSettings({ [name]: value });
  };
  const cancel = () => {
    viewModel.hide();
  };

  const submit = () => {
    viewModel.submit();
  };

  return (
    <div id="settingsModal" className="modal show" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center">
              Settings for {viewModel.user ? viewModel.user.u_name : "User"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={cancel}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {[
              {
                name: "defaultLogEditFormat",
                label: "Default Log Editing Time Format",
                value: viewModel.userSettings.defaultLogEditFormat,
                options: ["hh 'hours and' mm 'minutes'", "hh:mm:ss", "h:mm:ss", "hh:mm", "h:mm"],
              },
{
                name: "darkMode",
                label: "Dark Mode",
                value: viewModel.userSettings.darkMode,
              },
              {
                name: "showEmojis",
                style: { display: "none" },
                label: "Show Emojis",
                value: viewModel.userSettings.showEmojis,
              },
              {
                name: "titleChange",
                label: "Cross Site Title Change",
                value: viewModel.userSettings.titleChange,
              },

              // { name: 'showManagementTab', label: 'Show Maintain & Reports', value: viewModel.userSettings.showManagementTab },
            ].map((setting) => (
              <div
                className="form-check"
                style={setting.style ? setting.style : undefined}
                key={setting.name}
              >
                {setting.options ? (
                  <div>
                    <label htmlFor={setting.name} className="form-label">
                      {setting.label}
                    </label>
                    <select
                      id={setting.name}
                      className="form-select"
                      value={setting.value}
                      onChange={(e) =>
                        handleSelectChange(setting.name, e.target.value)
                      }
                    >
                      {setting.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={setting.name}
                      checked={setting.value}
                      onChange={handleCheckboxChange(setting.name)}
                    />
                    <label className="form-check-label" htmlFor={setting.name}>
                      {setting.label}
                    </label>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={cancel}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ISettingsModal {
  viewModel: IUserSettingsViewModel;
}
