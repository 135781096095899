import React, { useEffect } from 'react';
import { Duration } from "luxon";
import { Gear } from 'react-bootstrap-icons';
import LogCard from './LogCard.tsx';
import Autocomplete from './Autocomplete.tsx';
import { ITodaysLogsViewModel } from '../js/viewmodels/TodaysLogsViewModel';
import ActiveLogModalViewModel, { IActiveLogModalViewModel } from '../js/viewmodels/ActiveLogModalViewModel.ts';
import Customer from '../js/classes/Customer.ts';
import AlertViewModel, { IAlertViewModel } from '../common/js/viewmodels/AlertViewModel.ts';
import AlertModal from '../common/components/AlertModal.tsx';
import ActiveLogModal from './ActiveLogModal.tsx';
import DeleteLogModal from './DeleteLogModal.tsx';
import SettingsModal from './userSettings/modal/SettingsModal.tsx';
import { IUserSettingsViewModel } from '../js/viewmodels/UserSettingsViewModel.ts';
import RecentCustomerLogsModal from './todaysLogViewExtended/RecentLogsModal.tsx';
import RecentCustomerLogsViewModel, {IRecentLogViewModel} from '../js/viewmodels/RecentLogViewModel.ts';


export default function TodaysLogsScreen({
	model, settingsModel
}: ITodaysLogsScreen) {


    const showSettings = () => {
        settingsModel.show(model.user);
    };

    const recentModel = RecentCustomerLogsViewModel();
    const editModal = ActiveLogModalViewModel();
	const alertModel = AlertViewModel();
	const deleteModel = AlertViewModel();

    useEffect(() => {
        editModal.setCustomers(model.customers);
    }, [model.customers, editModal]);

    
    useEffect(() => {
        // work out the running total
        let total = 0;
        model.logs.forEach(log => {

            total += log.getSeconds();
        });

        let time = Duration.fromObject({
            years: 0,
            quarters: 0,
            months: 0,
            weeks: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: total,
            milliseconds: 0
        });
        let formatted = time.toFormat('h:mm:ss');
        model.setRunningTotal(`${formatted}`);
        
    }, [model.logs, model]);
    const error = (msg: string) => alertModel.show({ title: 'Error', msg });

    const add = (cust: Customer) => {
        model.add(cust, error);
    }

	return (
		<>
            <div className="row">
                <div className="input-field col s6 m9 m-0">
                    <Autocomplete
                        id="customerAutocomplete"
                        name="Customer"
                        setValue={add}
                        datalist={model.customers}
                        labelKey="name"
                        autoFocus={true}
                        clearOnComplete={true}
                        />
                </div>
                <div className="col s6 m3">
                    <button className="btn btn-primary" onClick={showSettings}>
                        <Gear className="left m-1"/>
                        <span className="m-3">Settings</span>
                    </button>
                </div>
            </div>
            <div className="row mb-3">
                <span>Running total: { model.runningTotal }</span>
            </div>
            <LogCards
                recentModel={recentModel}
                model={model}
                editModel={editModal}
                deleteModel={deleteModel}
                error={error}
                
                />
            <RecentCustomerLogsModal model={recentModel}/>
            <SettingsModal viewModel={settingsModel}/>
			<AlertModal model={alertModel}/>
            <ActiveLogModal model={editModal} alertModel={alertModel} todaysLogsModel={model}/>
            <DeleteLogModal model={model} alertModel={deleteModel} error={error}/>
        </>
	)
}

function LogCards({ model, editModel, deleteModel, recentModel, error }: ITodaysLogsScreenBothModels) {
    model.logs.sort((a, b) => {
        if (a.completed && !b.completed) return -1;
        if (!a.completed && b.completed) return 1;
        return 0;
    });

    const logs = model.logs.map((log, index) => (
        <LogCard
            key={log.id || index} // Using log.id if it exists, otherwise using index as a fallback.
            log={log}
            model={model}
            editModel={editModel}
            deleteModel={deleteModel}
            recentModel={recentModel}
            error={error}
        />
    ));

    return (
        <div className="row">
            {logs}
        </div>
    );
}


interface ITodaysLogsScreen{
    model: ITodaysLogsViewModel;
    settingsModel: IUserSettingsViewModel;
}

interface ITodaysLogsScreenBothModels{
    recentModel: IRecentLogViewModel;
    model: ITodaysLogsViewModel;
    editModel: IActiveLogModalViewModel;
    deleteModel: IAlertViewModel;
    error: (msg: string) => void;
}