import React, { useState, useEffect } from "react";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import ErrorDiv from "../../common/ErrorModal";
import User from "../../../js/classes/User.ts";
import CustomerGroup from "../../../js/classes/CustomerGroup.ts";
import IconButton from "../../IconButton.tsx"
import { PersonBadge     } from 'react-bootstrap-icons';

interface AssignUserGroupModalProps {
  usersSelected: User[];
  hubgroups: CustomerGroup[];
  callback: (updatedUsers: User[], isSuccess: boolean, message?: string) => void;
}

const AssignUserGroupModal: React.FC<AssignUserGroupModalProps> = ({ usersSelected, hubgroups, callback }) => {
  const [show, setShow] = useState(false);
  const [groups, setGroups] = useState<CustomerGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<CustomerGroup | null>(null);
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleClose = (isSuccess: boolean, message?: string) => {
    setShowResponseDiv(false);
    setShow(false);
    setSelectedGroup(null);
    callback(usersSelected, isSuccess, message);
  };

  const handleShow = () => {
    setShow(true);
    const groupss = hubgroups.filter(group => !usersSelected.every(user => user.u_groups.includes(group.g_name))); 
    setGroups(groupss);
    
  };


  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
  };

  const handleAssign = async () => {
    if (!selectedGroup) {
      displayError("Please select a group to assign.");
      return;
    }

    try {
      const url = '/api/groups/assign/users';
      const payload = {
        groupId: selectedGroup.g_id,
        users: usersSelected.map(user => user.u_name),
      };

      const result = await HttpClientUtil.postRequest(url, payload);

      if (!result.success) {
        displayError(result.message);
        handleClose(false);  // Close with a failure
        return;
      }

      // If successful, add the group to each user in usersSelected
      usersSelected.forEach(user => {
        if (user instanceof User) {
          user.addGroup(selectedGroup.g_name);
        } else {
          console.error('Not an instance of User:', user);
        }
      });
      handleClose(true);  // Close with success
    } catch (error) {
      console.error("Error assigning users to group:", error);
      displayError("Failed to assign users to group.");
      handleClose(false);  // Close with a failure
    }
  };

  return (
    <>
      <IconButton
                btnStyle="btn btn-primary mx-1"
                text="Assign Group"
                onClick={handleShow}
                icon={<PersonBadge   />}
                show={true}
                />
      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Assign User Groups</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>

              <div className="modal-body">
                <ErrorDiv
                  success={false}
                  errorMessage={responseMessage}
                  isVisible={showErrorDiv}
                  onClose={() => setShowResponseDiv(false)}
                />

                <div className="list-group mb-3">
                  {groups.map((group) => (
                    <button
                      type="button"
                      className={`list-group-item list-group-item-action ${selectedGroup && selectedGroup.g_id === group.g_id ? "active" : ""}`}
                      key={group.g_id}
                      onClick={() => setSelectedGroup(group)}
                    >
                      <div>
                        <strong>{group.g_name}</strong>
                        <br />
                        <small>{group.g_description}</small>
                      </div>
                    </button>
                  ))}
                </div>

                <div className="form-group">
                  <label>Selected Users:</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    readOnly
                    value={usersSelected.map(user => user.u_name).join(", ")}
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => handleClose(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleAssign}>
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignUserGroupModal;
