import React from 'react';
import { Play, Pause, Pencil, InfoCircleFill, TrashFill, CheckLg } from 'react-bootstrap-icons';
import InputField from '../common/components/InputField.tsx';
import NewLog from '../js/classes/NewLog';
import { ITodaysLogsViewModel } from '../js/viewmodels/TodaysLogsViewModel.ts';
import { IActiveLogModalViewModel } from '../js/viewmodels/ActiveLogModalViewModel.ts';
import IconButton from './IconButton.tsx';
import { IAlertViewModel } from '../common/js/viewmodels/AlertViewModel.ts';
import { IRecentLogViewModel } from '../js/viewmodels/RecentLogViewModel.ts';
// import  from './SubmitLogModal.tsx';

export default function LogCard(args: ILogAndBothModels){

    const { log, model, editModel, deleteModel, recentModel, error } = args;
    
    if (model.activeLog.id === log.id){
        return (<ExpandedLogCard log={log} model={model} editModel={editModel} deleteModel={deleteModel} recentModel={recentModel} error={error}/>);
    }else{
        return (<CollapsedLogCard log={log} model={model} editModel={editModel} deleteModel={deleteModel} recentModel={recentModel} error={error}/>);
    }

}

function HeaderPlayIcon(args: ILogAndBothModels){

    const { log, model } = args;

    const isCompleted = log.completed; 
    
    if (isCompleted)
        return <CheckLg className="right"/>;

    if (log.id === model.runningLog.id)
        return (<Play className="right"/>);
    else
        return (<Pause className="right"/>);

}

function Header(args: ILogAndBothModels){

    const { log, model, editModel, deleteModel, recentModel, error } = args;

    const collapseOrExpand = () => {
        const logToShow = (model.activeLog.id === log.id) ? null : log;
        model.activeLog.setLog(logToShow);
    };

    const time: string = (model.runningLog.id === log.id) ? model.runningLog.display : log.displayTime();

    return (
        <div className="card-header" onClick={collapseOrExpand}>
            <span>{ log.customer.code } { time } { log.caller } - { log.problem }</span>
            <HeaderPlayIcon log={log} model={model} editModel={editModel} deleteModel={deleteModel} recentModel={recentModel}  error={error}/>
        </div>
    );

}

function CollapsedLogCard(args: ILogAndBothModels){

    const { log, model, editModel, deleteModel, recentModel, error } = args;

    return (
        <div className="card ps-0 pe-0">
            <Header log={log} model={model} editModel={editModel} deleteModel={deleteModel} recentModel={recentModel} error={error}/>
        </div>
    );

}

function PublicNotes(args: ILog){

    const { log } = args;

    if (log.customer.publicNote.length === 0){
        return (<></>);
    }

    var style = {color:'darkgreen'};

    return (
        <div className="col s12">
            <p style={style}>{ log.customer.publicNote }</p>
        </div>
    );

}

function PrivateNotes(args: ILog){

    const { log } = args;

    if (log.customer.privateNote.length === 0){
        return (<></>);
    }

    var style = {color:'dodgerblue'};

    return (
        <div className="col s12">
            <p style={style}>{ log.customer.privateNote }</p>
        </div>
    );

}

function PrbPrompt(args: ILog){

    const { log } = args;

    if (log.customer.isPrb())
        return (
            <div className="col s12 row">
                <p className="p-10 red-text">*PRB number required</p>
            </div>
        );
    else
        return (<></>);

}

function SupportTime(args: ILog){

    const { log } = args;

    if (log.customer === null){
        return (<></>);
    }

    if (parseFloat(log.customer.customerHoursText) > 0){
        return (
            <div className="col s12">
                <p>
                    <b>{ log.customer.name }</b> has <span style={{ fontWeight: 'bold', color: 'green' }}>{ log.customer.customerHoursText }</span> support time remaining this period.<br/>
                    *If a customer asks how many support hours they have left, do not tell them. Put them through to Brett instead.
                </p>
            </div>
        );
    }else{
        return (
            <div className="col s12">
                <p className="">
                    <b>{log.customer.name}</b> is 
                    <span 
                        style={{ fontWeight: 'bold', color: 'red' }}>
                    {' '}{log.customer.customerHoursText}{' '}
                    </span> 
                    over their support time for this period.<br/>
                    *If a customer asks how many support hours they have left, do not tell them. Put them through to Brett instead.<br/>
                    Note that this figure might not be accurate if the logs haven't been rolled yet.
                </p>
            </div>
        );
    }

}





function Footer(args: ILogAndBothModels){

    const { log, model, editModel, deleteModel, recentModel, error } = args;

    if (model.requestInProgress){
        return (<></>);
    }

    const isRunning = model.runningLog.id === log.id;
    const showPaused = !model.requestInProgress && !isRunning;
    const showRunning = !model.requestInProgress && isRunning;
    const showAlways = !model.requestInProgress;
    const isComplete = log.completed;

    const pause = () => model.pause(error, () => {});
    const resume = () => model.resume(log, error);
    const edit = () => editModel.show(log);
    const recent = () => {
        recentModel.show(log.customer);
        recentModel.fetchCustomerLogs(log.customer, error );
    }
    
    // model.recent(log.customer);
    const promptDelete = () => {
        deleteModel.show({
            title: "Delete Log",
            msg: "Are you sure you want to delete this log entry?"
        });

        // todo: delete log
    };

    const submitLog = () => {
        
        model.submitLog(log, error, () => {
            
        });
    }

    return (
        <div className="card-footer">
            <div className="row">
                
            {!isComplete && (
                <>
                <div className="col">
                    
                    <IconButton
                        btnStyle="danger"
                        text="Pause"
                        onClick={pause}
                        icon={<Pause/>}
                        show={showRunning}
                        />

                    <IconButton
                        btnStyle="primary"
                        text="Resume"
                        onClick={resume}
                        icon={<Play/>}
                        show={showPaused}
                        />

                    <IconButton
                        btnStyle="warning"
                        text="Edit"
                        onClick={edit}
                        icon={<Pencil/>}
                        show={showPaused}
                        />

                    <IconButton
                        btnStyle="info"
                        text="Recent"
                        onClick={recent}
                        icon={<InfoCircleFill/>}
                        show={showAlways}
                        />

                </div>

                <div className="col align-right">
                    <IconButton
                        btnStyle="success"
                        text="Submit"
                        onClick={submitLog}
                        icon={<CheckLg/>}
                        show={!isRunning}
                        inProgress={model.requestInProgress}
                        inProgressText="Submitting..."
                        />

                    <IconButton
                        btnStyle="danger"
                        text="Delete"
                        onClick={promptDelete}
                        icon={<TrashFill/>}
                        show={showPaused}/>

                </div>
            
                </>
            )}
            </div>
        </div>
    );
}

function ExpandedLogCard(args: ILogAndBothModels){

    const { log, model, editModel, deleteModel, recentModel, error } = args;

    return (
        <div className="card ps-0 pe-0">
            <Header log={log} model={model} editModel={editModel} deleteModel={deleteModel} recentModel={recentModel} error={error}/>
            <Body log={log} model={model} editModel={editModel} deleteModel={deleteModel} recentModel={recentModel} error={error}/>
			<Footer log={log} model={model} editModel={editModel} deleteModel={deleteModel} recentModel={recentModel} error={error}/>
		</div>
    );

}

function Body(args: ILogAndBothModels){

    const { log, model, error } = args;

    const change = () => {
        model.activeLog.change((updatedLog: NewLog) => {
            model.save(updatedLog, error, () => {
                //success
            });
        });
    };

    return (
        <div className="card-body">

            <PrbPrompt log={log}/>

            <PublicNotes log={log}/>

            <PrivateNotes log={log}/>

            <InputField
                name="Caller:"
                value={model.activeLog.caller}
                setValue={model.activeLog.setCaller}
                onBlur={change}
                maxlen={30}
                autoFocus={true}
                readonly={model.activeLog.completed}
            />

            <InputField
                name="Problem:"
                value={model.activeLog.problem}
                setValue={model.activeLog.setProblem}
                onBlur={change}
                maxlen={250}
                readonly={model.activeLog.completed}
            />

            <InputField
                name="Solution:"
                value={model.activeLog.solution}
                setValue={model.activeLog.setSolution}
                onBlur={change}
                maxlen={250}
                readonly={model.activeLog.completed}
            />

            <SupportTime log={log}/>

        </div>
    );
}

interface ILog{
    log: NewLog;
}

interface ILogAndBothModels{
    log: NewLog;
    model: ITodaysLogsViewModel;
    editModel: IActiveLogModalViewModel;
    deleteModel: IAlertViewModel;
    recentModel: IRecentLogViewModel
    error: (msg: string) => void;
}

