import React from "react";
import ErrorDiv from "../common/ErrorModal.jsx";
import useForm from "./hooks/useForm";
import { Typeahead } from "react-bootstrap-typeahead";
import { DateTime } from "luxon";
import DateTimePicker from "react-datetime"; // import react-datetime for datepicker
import "react-datetime/css/react-datetime.css"; // Import the styles

const FormComponent = ({ title, fields, customerDetails, customFieldRender, customerOptions }) => {
  const {
    formData,
    handleTypeaheadChange,
    handleInputChange,
    handleCheckboxChange,
    handleDateChange, 
    handleSave,
    showErrorDiv,
    setShowResponseDiv,
    responseMessage,
    isSuccess,
  } = useForm(fields, customerDetails, customerOptions);

  const renderField = (field) => {
    if (customFieldRender) {
      return customFieldRender(field, formData, handleTypeaheadChange, handleInputChange, handleCheckboxChange);
    }

    switch (field.type) {
      case "checkbox":
        return (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={field.id}
              name={field.id}
              checked={formData[field.id] === 'Y'}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor={field.id}>
              {field.label}
            </label>
          </div>
        );
      case "typeahead":
        return (
          <Typeahead
            id={field.id}
            style={{ height: "40px" }}
            labelKey="label"
            onChange={(selected) => handleTypeaheadChange(selected, field.id)}
            options={customerOptions}
            placeholder="Enter Bill code..."
            selected={formData[field.id]}
            className="form-control"
          />
        );
      case "datetime-local":
        const dateValue = formData[field.id];
        let validDate = dateValue && DateTime.fromISO(dateValue).isValid
          ? DateTime.fromISO(dateValue).toJSDate()
          : '';
        
        return (
          <DateTimePicker
            id={field.id}
            value={validDate || ''}
            dateFormat="DD-MM-YYYY"
            timeFormat="HH:mm"
            className="form-control"
            onChange={(value) => handleDateChange(field.id, value)}
            inputProps={{ placeholder: "Select a date" }}
          />
        );
      case "select":
        return (
          <select
            id={field.id}
            name={field.id}
            className="form-control"
            value={formData[field.id] || ""}
            onChange={handleInputChange}
          >
            <option value="" disabled>
              Select {field.label}
            </option>
            {field.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type={field.type}
            className="form-control"
            id={field.id}
            name={field.id}
            value={formData[field.id]}
            onChange={handleInputChange}
          />
        );
    }
  };
  // };

  return (
    <div>
      <ErrorDiv
        success={isSuccess}
        errorMessage={responseMessage}
        isVisible={showErrorDiv}
        onClose={() => setShowResponseDiv(false)}
      />
      <form className="container mt-3">
        <h3 className="mb-4">{title}</h3>
        <div className="row g-3">
          {fields.map((field) => (
            <div className="col-md-6" key={field.id}>
              <div className="row mb-3">
                <label htmlFor={field.id} className="col-sm-4 col-form-label">
                  {field.label}
                </label>
                <div className="col-sm-8">
                  {renderField(field)}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mb-3">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              className="btn btn-primary me-md-2"
              type="button"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
