import { useEffect, useState } from "react";
import { DateTime, Duration } from "luxon";
import NewLog from "../classes/NewLog";

export default function RunningLogViewModel(): IRunningLogViewModel{

    const defaultDuration = Duration.fromObject({
        years:0,
        quarters:0,
        months:0,
        weeks:0,
        days:0,
        hours:0,
        minutes:0,
        seconds:0,
        milliseconds:0
    });

    const [id, setId] = useState<number>(-1);
    const [total, setTotal] = useState<Duration>(defaultDuration);
    const [lastResumed, setLastResumed] = useState<DateTime | null>(null);
    const [display, setDisplay] = useState<string>("0:00:00");

    const start = (log: NewLog) => {
        setId(log.id);
        setTotal(log.total);
        setDisplay(log.total.toFormat('h:mm:ss')); // Set this before resuming
        setLastResumed(DateTime.now());
    };

    const pause = () => {
        if (lastResumed === null) return { id:-1, seconds:0 };
        const now = DateTime.now();
        const seconds = now.diff(lastResumed).as('seconds');
        const returnData = { id, seconds };
        setId(-1);
        setLastResumed(null);
        return returnData;
    };

    useEffect(() => {
        const comInterval = setInterval(() => {
            if (lastResumed === null) return;
            const now = DateTime.now();
            const diff = now.diff(lastResumed);
            const elapsed = total.plus(diff);
            setDisplay(elapsed.toFormat('h:mm:ss'));
        }, 1000);
        return () => clearInterval(comInterval);
    },[lastResumed, total]);

    return {
        id,
        display,
        start, pause,
    }

}

export interface IRunningLogViewModel{
    id: number;
    display: string;
    start: (log: NewLog) => void;
    pause: () => { id: number, seconds: number };
}